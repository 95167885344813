<template>
  <div>
    <div class="md:grid gap-12 md:grid-cols-2 px-8 sm:px-12 lg:px-24 py-8 md:min-h-2/3-screen">
      <div>
        <h2 class="pb-8 block md:hidden font-medium">Page Custom Supply</h2>
        <div
          class="w-full h-64 overflow-hidden mx-auto items-center justify-center mb-8 hidden md:block"
        >
          <img
            src="/images/static/homePage_aaron-huber-G7sE2S4Lab4-unsplash.jpg"
            alt="Kitchen decor"
            class="object-cover object-center"
          />
        </div>
        <div>
          <h3 class="text-action">Customer Support</h3>
          <div class="my-2 mt-4">
            <a :href="`tel:${siteSettings.phone}`">
              <font-awesome-icon class="text-xl mr-4" icon="phone-alt" />{{ siteSettings.phone }}
            </a>
          </div>

          <div class="my-2">
            <a :href="`mailto:${siteSettings.email}`">
              <font-awesome-icon class="text-xl mr-4" icon="envelope" />{{ siteSettings.email }}
            </a>
          </div>

          <div class="text-primary">
            <font-awesome-icon class="text-xl mr-4" icon="map-marked" />{{ siteSettings.address }}
          </div>
        </div>
      </div>
      <form @submit.prevent="sendEmail" v-if="!emailSent">
        <h1 class="hidden md:block font-medium">Page Custom Supply</h1>
        <h3 class="py-3 pt-8 text-action">Contact Us</h3>
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div class="flex flex-col">
            <label for="name" class="text-primary font-semibold">Name</label>
            <input
              type="text"
              v-model="name"
              class="border border-gray-200 styled"
              placeholder="Name"
              required
            />
          </div>
          <div class="flex flex-col">
            <label for="name" class="text-primary font-semibold">Email</label>
            <input
              type="email"
              v-model="email"
              class="border border-gray-200 styled"
              placeholder="name@example.com"
              required
            />
          </div>
        </div>
        <div class="flex flex-col my-4">
          <label for="name" class="text-primary font-semibold">Subject</label>
          <input type="text" v-model="subject" class="border border-gray-200 styled" required />
        </div>
        <div class="flex flex-col my-4">
          <label for="name" class="text-primary font-semibold">Message</label>
          <textarea
            type="text"
            v-model="message"
            class="border border-gray-200 styled"
            placeholder="What can we help you with?"
            required
          >
          </textarea>
        </div>
        <div class="text-center sm:text-right">
          <button
            class="btn-action w-full sm:w-1/3 md:w-40 rounded-full md:rounded my-2 font-medium md:font-normal"
          >
            Submit
          </button>
        </div>
      </form>
      <div v-else class="p-4 text-center">
        <h3>Your email has been sent. We will contact you within 24 hours.</h3>
      </div>
    </div>
    <div class="md:col-span-2 md:grid md:grid-cols-2 md:hidden">
      <div class="sm:h-72 sm:flex sm:items-center sm:justify-center overflow-hidden">
        <img
          src="/images/static/homePage_aaron-huber-G7sE2S4Lab4-unsplash.jpg"
          alt="Kitchen decor"
          class="object-cover"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: null,
      email: null,
      subject: null,
      message: null,
      emailSent: false,
    };
  },
  computed: {
    siteSettings() {
      return this.$store.state.siteSettings;
    },
  },
  methods: {
    sendEmail() {
      this.$store.dispatch('contactUs', {
        name: this.name,
        email: this.email,
        subject: this.subject,
        message: this.message,
      });
      this.emailSent = true;
    },
  },
};
</script>

<style scoped>
label {
  margin-bottom: 0.25rem;
}
</style>
